.search_bar {
  width: 400px;
  margin: auto;

  margin-bottom: 50px;
}

.tag_wrapper {
  position: absolute;
  right: 10px;
}

.ProductInfoDisplay {
  border: 1px solid #d9d9d9;

  margin: 30px 200px;
  padding: 10px;

  .title {
    font-size: 18px;
    text-align: center;
  }

  ul img {
    max-width: 300px;
    max-height: 300px;
    vertical-align: middle;
  }
}

.ant-descriptions-view img {
  max-width: 200px;
  max-height: 200px;
}

.SetProductTag {
  .selector {
    width: calc(100% - 96px);
    margin-right: 10px;
  }
}
