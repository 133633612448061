.resultCount {
  text-align: center;
  margin-bottom: 10px;
}

.title {
  font-size: 18px;
}

.input {
  width: 30%;
  min-width: 300px;

  margin: auto;
  margin-bottom: 20px;
}

.pagination_wrapper {
  text-align: center;

  margin-top: 20px;
  margin-bottom: 200px;
}

.preview {
  .wrapper {
    width: 350px;
  }
}
