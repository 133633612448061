.header {
  margin-bottom: 30px;
}

.section_title {
  font-size: 18px;
  font-weight: bold;
}

.tag_item {
  margin: 15px 0px;
}
