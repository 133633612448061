.wrapper {
  padding: 10px 0px;
  border: 1px dotted rgba(100, 100, 100, 0.6);
  margin-top: 50px;
  margin-bottom: 30px;
}

.sidemenu_wrapper {
  position: fixed;
  left: 0;
  top: 0;
}

.sidemenu {
  width: 200px !important;
}

.sidemenuLogo {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  height: 100px;
}

.contents_wrapper {
  margin-left: 200px;
  padding: 0 20px;
}

.inputs_table {
  border-collapse: separate;
  border-spacing: 10px 10px;
  font-size: 14px;
  width: 80%;

  td {
    border: 1px solid lightgray;
    padding: 10px;
  }
}

.load_btns {
  text-align: center;
}

.pagination_wrapper {
  text-align: center;
}

.modalwrapper {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.9);
  z-index: 2000;
  width: 100vw;
  height: 100%;
}

.modalcontents {
  position: relative;
  width: fit-content;
}

.title {
  text-align: center;
}
